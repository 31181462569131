import './Body.css';
import { getRedirectResult, signInWithRedirect } from "firebase/auth";
import { authProvider, firebaseApp, firebaseAuth } from "./firebase-config";
import { useCallback, useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import loader from "./loader.gif";


function Body() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("login") === "true") {
    window.history.pushState({}, document.title, window.location.pathname +
        "?action=" + urlParams.get("action") +
        "&class=" + urlParams.get("class")
    );
    login();
  }
  let action = "";
  if (urlParams.get("action") === "registreerimine") {
    action = "https://katsed.real.edu.ee/katsed/?next=" + urlParams.get("class");
  } else if (urlParams.get("action") === "tulemused") {
    action = "https://katsed.real.edu.ee/katsed/tulemused/" + urlParams.get("class") + ".php";
  }

  const [data, setData] = useState({
    encryptedValue: '',
    iv: '',
  });
  // const [loading, setLoading] = useState(false);
  const functions = getFunctions(firebaseApp);

  const getEncryptedToken = useCallback(() => {
    const getToken = httpsCallable(functions, 'getToken');
    getToken()
        .then((result) => {
          setData({
            encryptedValue: result.data.encryptedValue,
            iv: result.data.iv,
          });
        })
        .catch((error) => {
          console.log(error);
        });
  }, [functions]);

  useEffect(() => {
    if (data.encryptedValue !== '') {
      document.getElementById("submitData").submit();
    }
  }, [data]);

  useEffect(() => {
    // setLoading(true);
    getRedirectResult(firebaseAuth)
        .then((result) => {
          if (!result) {
            // setLoading(false);
            return;
          }
          getEncryptedToken();
          // setLoading(false);
        })
        .catch(() => {
          // setLoading(false);
        });
  }, [getEncryptedToken])

  function login() {
    signInWithRedirect(firebaseAuth, authProvider);
  }

  /*
  function signOut() {
    firebaseAuth.signOut().then(() => {
    }).catch(() => {
    });
  } */

  return (
      <div className="body">
        <img src={loader} className={"loader"} alt="loader"/>
        <form method="POST" id="submitData" action={action}>
          <input type="text" id="lkoodtoken" name="lkoodtoken" hidden value={data.encryptedValue}></input>
          <input type="text" id="iv" name="iv" hidden value={data.iv}></input>
          <input type="text" id="next" name="next" hidden value={urlParams.get("class")}></input>
        </form>
      </div>
  );
}

export default Body;
