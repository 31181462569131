import './Footer.css';
import logo from './logo-footer.svg';

function Footer() {
    return (
        <div className="footer">
            <div>
                <img src={logo} className="footer-logo" alt="footer" />
            </div>

            <div className="footer-copy">© 2023 Tallinna Reaalkool</div>
        </div>
    );
}

export default Footer;
