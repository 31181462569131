import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBY3O4vOfu0ebjcQxZlkjed3-tzm_gLcik",
    authDomain: "vastuvott.real.edu.ee",
    projectId: "vastuvott-17e5d",
    storageBucket: "vastuvott-17e5d.appspot.com",
    messagingSenderId: "788311054624",
    appId: "1:788311054624:web:b25e6590f1cb12b089df45"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const authProvider = new OAuthProvider('oidc.tara');