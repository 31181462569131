import './App.css';
import logo from './logo-header.svg';

function Header() {
    return (
        <header className="header">
            <div className="header-wrapper">
                <div className="header-content">
                    <div className="header-logo">
                        <a href="https://real.edu.ee">
                            <img src={logo} alt="header"/>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
